import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Navigation, Lazy, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const StyledSwiper = styled.div`
  position: relative;
  min-width: 100%;
`;
const Button = styled.a<{
  controllersIn?: boolean;
}>`
  width: 48px;
  height: 48px;
  line-height: 48px;
  border: solid 1px rgb(234, 234, 234);
  background-color: transparent;
  position: absolute;
  top: 50%;
  margin-top: -24px;
  display: block;
  cursor: pointer;
  z-index: 2;
  text-align: center;
  color: ${props => props.theme.colors.neutrals.gray};

  &:before {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 1405px) {
    background-color: #ffffff;
    color: black;
    border: 0;
  }

  &.disabled {
    display: none;
  }

  ${props =>
    props.controllersIn
      ? `
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          border: 0;
        `
      : null};

  &.next {
    left: auto;
    right: ${props => (props.controllersIn ? '20px' : '-70px')};

    @media (max-width: 1405px) {
      right: 20px;
    }
  }

  &.previous {
    right: auto;
    left: ${props => (props.controllersIn ? '20px' : '-70px')};
    transform: rotate(180deg);

    @media (max-width: 1405px) {
      left: 20px;
    }
  }

  @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
    display: none;
  }
`;

type Props = React.PropsWithChildren<{
  className?: string;
  itemPerView?: number;
  spaceBetween?: number;
  loopSlider?: boolean;
  controllersIn?: boolean;
  activeSlideIndex?: number;
}>;
const Carousel = (props: Props) => {
  const swiperRef = useRef(null);
  const { className } = props;
  const sectionId = useRef(Date.now().toString(16));
  const prevClassname = `swiper-custom-previous-${sectionId.current}`;
  const nextClassname = `swiper-custom-next-${sectionId.current}`;

  useEffect(() => {
    swiperRef.current.slideTo(props.activeSlideIndex);
  }, [props.activeSlideIndex]);

  const itemPerView = props.itemPerView || 4;

  return (
    <>
      <StyledSwiper className={className}>
        <Swiper
          style={{ height: 'max-content' }}
          onSwiper={swiper => {
            swiperRef.current = swiper;
          }}
          modules={[Lazy, Navigation, Controller]}
          spaceBetween={30}
          slidesPerView={1}
          slidesPerGroup={1}
          navigation={{
            prevEl: `.${prevClassname}`,
            nextEl: `.${nextClassname}`,
            disabledClass: 'disabled',
          }}
          loop={props.loopSlider}
          breakpoints={{
            1240: {
              slidesPerView: itemPerView,
              spaceBetween: props.spaceBetween ?? 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: props.spaceBetween ?? 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            250: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          {React.Children.map(props.children, (child, i) => (
            <SwiperSlide key={i}>{child}</SwiperSlide>
          ))}
        </Swiper>
        <Button
          className={`next ygb-icon-slider_arrow ${nextClassname}`}
          controllersIn={props.controllersIn}
        />
        <Button
          className={`previous ygb-icon-slider_arrow ${prevClassname}`}
          controllersIn={props.controllersIn}
        />
      </StyledSwiper>
    </>
  );
};
export default Carousel;
