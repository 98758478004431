import React from 'react';
import { Box, Flex } from '@components/layout/Grid';
import { SubTitle } from '@components/texts';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

const Header = styled.h3`
  font-size: 18px;
  color: ${props => props.theme.colors.neutrals.black};
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  padding: 0;
`;

type Props = {
  className?: string;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  subTitleStyle?: FlattenInterpolation<ThemeProps<DefaultTheme>> | string;
  secondSubtitle?: React.ReactNode;
  button?: React.ReactNode;
};

const SectionHeader = (props: Props) => (
  <Flex
    className={props.className}
    justifyContent="space-between"
    alignItems="center"
    mb={15}
  >
    <Box>
      <Header className="SectionHeader">{props.title}</Header>
      {props.subTitle && (
        <SubTitle
          {...(props.subTitleStyle ? { customStyle: props.subTitleStyle } : {})}
        >
          {props.subTitle}
        </SubTitle>
      )}
      {props.secondSubtitle && <SubTitle>{props.secondSubtitle}</SubTitle>}
    </Box>
    {props.button ? (
      <Box flexShrink={0} ml={[40, 40, 0]}>
        {props.button}
      </Box>
    ) : null}
  </Flex>
);

export default SectionHeader;
